import { useContext, useEffect, useState } from "react"
import MyContext from "../Context"
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "./Loader";
import toast from "react-hot-toast";

const Prize = () => {
  const { imageURL, setImageURL, displayErrorMessage, promoCode, setPromoCode } = useContext(MyContext);
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');

  const getImage = async () => {
    setIsLoading(true);
    try {
      const data = {
        claim_id: id
      }
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/prize/get`, data);
      const image = response.data.data.image
      const code = response.data.data.promo_code
      setImageURL(image)
      setPromoCode(code)

    } catch (error) {
      console.log(error);
      const errorMessage = error.response?.data?.message || 'Failed to get prize'
      setApiError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }

  const copyPromoCode = () => {
    navigator.clipboard.writeText(promoCode)
    toast.success('Copied!');
  }

  useEffect(() => {
    getImage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="image-center" style={{...(apiError && {minHeight: '300px'})}}>
        {apiError && <div className='api-error' style={{marginTop: 0, minHeight:'300px', alignContent: 'center'}}>{displayErrorMessage(apiError)}</div>}
        {isLoading && <Loader />}
        {imageURL && 
          <div>
            <img src={process.env.REACT_APP_BASE_URL+'/'+imageURL} alt="gift" />
            {promoCode && <div style={{backgroundColor: '#30706C', height: '55px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1.5rem', color: 'white'}}>
              <div>
                <span style={{marginRight: '7px'}}>PROMO CODE: {promoCode}</span>
                <img style={{width: '1.2rem', height: 'auto', cursor: 'pointer'}} src='/images/copy.png' alt="copy-icon" onClick={copyPromoCode} />
              </div>
            </div>}
          </div>
        }
    </div>
  )
}

export default Prize