import { BrowserRouter, Route, Routes } from "react-router-dom";
import FormPage from "./components/FormPage";
import PrizePage from "./components/PrizePage";
import NotFound from "./components/NotFound";
import { useState } from "react";
import MyContext from "./Context";
import Banner from "./components/Banner";
import { Toaster } from "react-hot-toast";
import ToasterComponent from "./components/ToasterComponent";

function App() {

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [promoCode, setPromoCode] = useState(null);

  const displayErrorMessage = (message) => {
    const lines = message.split('\n').filter(Boolean);

    if (lines.length === 1) {
      return (
        <p className="error-message">{lines[0]}</p>
      );
    } else {
      return (
        <>
          <p className="error-message">{lines[0]}</p>
          <p className="error-message-arabic">{lines[1]}</p>
        </>
      );
    }
  };

  const providerValue = {
    isFormSubmitted,
    setIsFormSubmitted,
    imageURL,
    setImageURL,
    displayErrorMessage,
    promoCode,
    setPromoCode
  }

  return (
    <main>
      <MyContext.Provider value={providerValue}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Banner imagePath={'/images/home-page-banner.jpg'} />} />
            <Route path=":id" element={<FormPage />} />
            <Route path="prize/:id" element={<PrizePage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        <ToasterComponent />
      </MyContext.Provider>
    </main>
  );
}

export default App;
