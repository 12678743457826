import Banner from "./Banner"
import Footer from "./Footer"
import Form from "./Form"
import Terms from "./Terms"

const FormPage = () => {
  return (
    <>
        <Banner imagePath={'/images/banner.jpeg'} />
        <Form />
        <Terms />
        <Footer />
    </>
  )
}

export default FormPage
