const Footer = () => {
    return (
        <div className="footer">
            <img src="/images/background.png" />
            <div className="social-icons">
                <div className="left">
                    <div className="top-icons">
                        <a href="https://x.com/herfyfsc" target="_blank"><img src="/images/twitter.png" alt="x"/></a>
                        <a href="https://www.instagram.com/herfyfsc/" target="_blank" ><img src="/images/instagram.png" alt="instagram"/></a>
                        <a href="https://www.youtube.com/channel/UCVEZKa37P9m6_JsYp6mtuig" target="_blank" ><img src="/images/youtube.png" alt="youtube"/></a>
                        <a href="https://www.tiktok.com/@herfyfsc" target="_blank" ><img src="/images/tiktok.png" alt="tiktok"/></a>
                    </div>
                    <span>Herfyfsc</span>
                </div>
                <div className="right">
                    <div className="top-icons">
                        <a href="https://www.facebook.com/herfyfscksa/" target="_blank" ><img src="/images/facebook.png" alt="facebook"/></a>
                    </div>
                    <span>herfyfscksa</span>
                </div>
            </div>
        </div>
    )
}

export default Footer
