const Terms = () => {
    return (
        <div className="terms-and-condition">
            <div className="eng col">
                <h1>Scan and Win with Herfy</h1>
                <br />
                <h4>The Amazing Prizes:</h4>
                <ol style={{marginLeft: 30, marginTop: 20, marginBottom: 20}}>
                    <li>Gaming Set</li>
                    <li>Gaming PC</li>
                    <li>MacBook Pro</li>
                    <li>MacBook Air</li>
                    <li>Recliner Chair</li>
                    <li>iPhone 15 Pro Max</li>
                    <li>PlayStation 5 x 3</li>
                    <li>LG Smart TV x 3</li>
                    <li>Apple Watch</li>
                    <li>Airpods</li>
                    <li>JBL Speaker</li>
                </ol>
                <h4>The Delicious Prizes:</h4>
                <ol style={{marginLeft: 30, marginTop: 20, marginBottom: 20}}>
                    <li>Free Chicken Burger on your next order!</li>
                    <li>4 Free Musahab with any kiddie meal!</li>
                    <li>Free 4 Nuggets on your next order</li>
                    <li>Free Pepsi and Free Fries with any Tortilla Sandwich!</li>
                    <li>2 Free Strips with any large combo!</li>
                    <li>Free ice cream with any regular combo!</li>
                    <li>Chicken Caesar salad for 10 SAR instead of 22 SAR!</li>
                </ol>
                <h4>How to Participate:</h4>
                <ol style={{marginLeft: 30, marginTop: 20, marginBottom: 20}}>
                    <li>Scan the QR code on the box to access the website.</li>
                    <li>Enter your name, mobile number and invoice number.</li>
                    <li>Once you enter your details, click “Claim Prize” to receive your gift.</li>
                    <li>Screenshot your prize and visit any Herfy store to redeem it.</li>
                    <li>Terms and conditions apply.</li>
                </ol>
                <h4>Terms and Conditions:</h4>
                <ol style={{marginLeft: 30, marginTop: 20, marginBottom: 20}}>
                    <li>
                        <p><strong>Claiming Your Prize:</strong></p>
                        To claim your prize, please visit any Herfy store in person.
                    </li>
                    <li>
                        <p><strong>Non-Transferable:</strong></p>
                        Prizes cannot be transferred to another person.
                    </li>
                    <li>
                        <p><strong>Collection Location:</strong></p>
                        The Amazing Prizes must be collected in Riyadh. Herfy is not responsible for delivering prizes to other locations. If you are unable to collect your prize from Riyadh, the prize will be forfeited, and no compensation will be provided.
                    </li>
                    <li>
                        <p><strong>Age Requirement:</strong></p>
                        If you are under 18 years of age, a guardian must accompany you to claim your prize.
                    </li>
                    <li>
                        <p><strong>Prize Redemption:</strong></p>
                        Once you view your prize on the website, the selection is final. No changes or withdrawals are permitted. You must screenshot your prize immediately upon submission of your details; failure to do so will result in forfeiture of the prize.
                    </li>
                    <li>
                        <p><strong>Exchange Policy:</strong></p>
                        Prizes cannot be exchanged with any cashier or restaurant employee.
                    </li>
                    <li>
                        <p><strong>Delivery Delays:</strong></p>
                        Due to the nature of the prizes, there may be delays in delivery. In such cases, Herfy is not liable for any complaints or compensation, whether financial or otherwise.
                    </li>
                    <li>
                        <p><strong>Publicity:</strong></p>
                        Herfy reserves the right to photograph winners and use the images on social media, official platforms, and other media outlets without objection or compensation.
                    </li>
                    <li>
                        <p><strong>Eligibility:</strong></p>
                        Herfy employees and their immediate family members (up to second degree) are not eligible to participate in the competition.
                    </li>
                    <li>
                        <p><strong>Legal Compliance:</strong></p>
                        Herfy is not responsible for any legal issues or consequences arising from participation in this competition.
                    </li>
                    <li>
                        <p><strong>Modifications:</strong></p>
                        Herfy reserves the right to modify or change these terms and conditions at any time without prior notice.
                    </li>
                    <li>
                        <p><strong>Agreement:</strong></p>
                        By claiming your prize, you agree to all the terms and conditions outlined above.
                    </li>
                    <li>
                        <p><strong>Expiration:</strong></p>
                        All prizes must be redeemed by September 30, 2024. Any prizes not claimed by this date will be considered invalid.
                    </li>
                    <li>
                        <p><strong>Contact Us:</strong></p>
                        For any inquiries, please contact Herfy customer service at 920001111.
                    </li>
                </ol>
            </div>
            <div className="arb col">
                <h1>امسح واربح مع هرفي</h1>
                <br />
                <h4>الجوائز الرهيبة:</h4>
                <ol style={{marginRight: 30, marginTop: 20, marginBottom: 20}}>
                    <li>مجموعة قيمنق</li>
                    <li>قيمنق بي سي</li>
                    <li>ماك بوك برو</li>
                    <li>ماك بوك إر</li>
                    <li>كرسي مساج</li>
                    <li>آيفون 15 برو ماكس</li>
                    <li>بلايستيشن 5 - عدد 3</li>
                    <li>تلفاز إل جي الذكي - عدد 3</li>
                    <li>آبل واتش</li>
                    <li>آبل ايربودز</li>
                    <li>جي بي إل سبيكر</li>
                </ol>
                <h4>الجوائز اللذيذة:</h4>
                <ol style={{marginRight: 30, marginTop: 20, marginBottom: 20}}>
                    <li>برجر دجاج مجاناً مع طلبك المقبل!</li>
                    <li>4 قطع مسحب مجاناً مع أي وجبة طفل!</li>
                    <li>4 قطع نقت مجاناً مع طلبك المقبل!</li>
                    <li>بطاطس وبيبسي مجاناً مع أي ساندويتش تورتيلا!</li>
                    <li>2 ستربس مجاناً مع أي وجبة حجم كبير!</li>
                    <li>آيس كريم مجاناً مع أي وجبة!</li>
                    <li>سلطة سيزر بـ10 ريال بدال 22 ريال!</li>
                </ol>
                <h4>كيفية المشاركة:</h4>
                <ol style={{marginRight: 30, marginTop: 20, marginBottom: 20}}>
                    <li>امسح رمز الـ QR الموجود على العلبة للوصول إلى الموقع الإلكتروني.</li>
                    <li>أدخل اسمك، ورقم جوالك، ورقم الفاتورة.</li>
                    <li>بعد إدخال بياناتك، اضغط على "استلام الجائزة" للحصول على جائزتك.</li>
                    <li>التقط لقطة شاشة لجائزتك وقم بزيارة أي فرع من فروع هرفي لاستلامها.</li>
                    <li>تطبق الشروط والأحكام.</li>
                </ol>
                <h4>الشروط والأحكام:</h4>
                <ol style={{marginRight: 30, marginTop: 20, marginBottom: 20}}>
                <li>
                    <p><strong>استلام الجائزة:</strong></p>
                    لاستلام الجوائز الرهيبة، يرجى زيارة أي فرع من فروع هرفي شخصيًا.
                </li>
                <li>
                    <p><strong>عدم تحويل الجائزة:</strong></p>
                    لا يمكن تحويل الجائزة إلى شخص آخر.
                </li>
                <li>
                    <p><strong>مكان الاستلام:</strong></p>
                    يجب استلام الجوائز الرهيبة من الرياض. هرفي غير مسؤول عن توصيل الجوائز إلى مواقع أخرى. في حال عدم قدرتك على استلام الجائزة من الرياض، سيتم إلغاؤها ولن يتم تقديم أي تعويض.   
                </li>
                <li>
                    <p><strong>متطلبات العمر:</strong></p>
                    إذا كنت تحت سن 18 عامًا، يجب أن يرافقك ولي أمر لاستلام الجائزة.
                </li>
                <li>
                    <p><strong>استبدال الجائزة:</strong></p>
                    بمجرد رؤيتك للجائزة على الموقع الإلكتروني، تعتبر هذه الجائزة نهائية ولا يمكن تغييرها أو سحب المشاركة. يجب عليك التقاط لقطة شاشة لجائزتك فور إدخال التفاصيل الخاصة بك، وفي حالة الفشل في ذلك، لن تتمكن من استلام الجائزة.
                </li>
                <li>
                    <p><strong>سياسة التبادل:</strong></p>
                    لا يمكن استبدال الجوائز مع أي كاشير أو موظف في المطعم.
                </li>
                <li>
                    <p><strong>تأخير التسليم:</strong></p>
                    نظرًا لطبيعة الجوائز المقدمة، قد يحدث تأخير في تسليم الجوائز؛ في هذه الحالة، لن تكون هرفي مسؤولة عن أي شكاوى أو تعويضات مالية أو غيرها.
                </li>
                <li>
                    <p><strong>النشر والتصوير:</strong></p>
                    تحتفظ هرفي بحق تصوير الفائزين واستخدام الصور على وسائل التواصل الاجتماعي والمنصات الرسمية ووسائل الإعلام الأخرى دون أي اعتراض من الفائزين أو حق في المطالبة بأي تعويض.
                </li>
                <li>
                    <p><strong>الأهلية:</strong></p>
                    موظفو هرفي وأقاربهم حتى الدرجة الثانية غير مؤهلين للمشاركة في المسابقة.
                </li>
                <li>
                    <p><strong>الامتثال القانوني:</strong></p>
                    لا تتحمل هرفي أي مسؤولية عن أي مسائل قانونية أو نتائج ناتجة عن المشاركة في هذه المسابقة.
                </li>
                <li>
                    <p><strong>التعديلات:</strong></p>
                    تحتفظ هرفي بالحق في تعديل أو تغيير الشروط والأحكام في أي وقت دون إشعار مسبق.
                </li>
                <li>
                    <p><strong>الموافقة:</strong></p>
                    بمطالبتك بالجائزة، فإنك توافق على جميع الشروط والأحكام المذكورة أعلاه.
                </li>
                <li>
                    <p><strong>فترة الصلاحية:</strong></p>
                    يجب استرداد جميع الجوائز قبل 30 سبتمبر 2024. أي جوائز لم تتم المطالبة بها قبل هذا التاريخ ستعتبر غير صالحة.
                </li>
                <li>
                    <p><strong>الاتصال بنا:</strong></p>
                    لأي استفسارات، يرجى الاتصال بخدمة عملاء هرفي على الرقم 920001111.
                </li>
                </ol>
            </div>
        </div>
    )
}

export default Terms