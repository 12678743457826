import { useContext, useState } from 'react';
import axios from 'axios';
import MyContext from '../Context';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import Loader from './Loader';

const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setIsFormSubmitted, isFormSubmitted, displayErrorMessage } = useContext(MyContext);

  const [formData, setFormData] = useState({
    name: '',
    mobileNo: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.name.trim()) errors.name = 'Please enter your name\nیرجى إدخال الإسم';
    if (!formData.mobileNo.trim()) errors.mobileNo = 'Please enter your mobile number\nیرجى إدخال رقم الجوال';
    else if (!/^\d{8,15}$/.test(formData.mobileNo)) errors.mobileNo = 'Invalid mobile number\nرقم الجوال غير صالح';
    return errors;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      setIsSubmitting(true);
      setSubmitError('');

      const data = {
        name: formData.name.trim(),
        contact_number: formData.mobileNo.trim(),
        request_url: window.location.href,
        uuid: id
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/prize/claim`, data);
        setIsFormSubmitted(true);
        const claim_id = response.data.data.claim_id;
        navigate(`/prize/${claim_id}`);
      } catch (error) {
        const errorMessage = error.response.data.message || 'Failed to submit form. Please try again.'
        setSubmitError(errorMessage);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  if (!id || isFormSubmitted) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="form-section">
      {
        (isSubmitting) && <Loader />
      }
      <form onSubmit={handleSubmit}>
        <h2>Enter your details below</h2>
        <span> أدخل الـمعلومات التالية</span>
        <div className="main-form-section">
        {submitError && <div className='api-error'>{displayErrorMessage(submitError)}</div>}
          <div className="wrapper inline">
            <div className="form-item">
              <div className="label">
                <h4>Name</h4>
                <span>الإسم</span>
              </div>
              <div className="field">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && <span className="error">{displayErrorMessage(errors.name)}</span>}
              </div>
            </div>
            <div className="form-item">
              <div className="label">
                <h4>Mobile No</h4>
                <span>رقم الجوال</span>
              </div>
              <div className="field">
                <input
                  type="number"
                  name="mobileNo"
                  value={formData.mobileNo}
                  onChange={handleChange}
                />
                {errors.mobileNo && <span className="error">{displayErrorMessage(errors.mobileNo)}</span>}
              </div>
            </div>
          </div>
          <button type="submit" disabled={isSubmitting}>
            <span>{isSubmitting ? 'Submitting...' : 'Claim Prize'}</span>
            <span>{isSubmitting ? 'جاري الإرسال...' : 'المطالبة بالجائزة'}</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
