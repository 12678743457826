import Footer from "./Footer"
import Prize from "./Prize"
import Terms from "./Terms"

const PrizePage = () => {
  window.scrollTo({ top: 0 });

  return (
    <>
      <Prize />
      <Terms />
      <Footer />
    </>
  )
}

export default PrizePage
